<template>
  <section class="min-h-screen bg-no-repeat bg-cover">
    <Main class="pb-0 pt-16">
      <slot />
    </Main>
    <Footer
      class="
        relative
        z-20
        before:shadow-xl
        before:rotate-180
        before:absolute
        before:top-0
        before:left-0
        before:w-full
        before:h-6
      "
      :class="footerClass"
      :contact="contact"
    />
  </section>
</template>

<script>
import Footer from "@/components/organisms/Footer.vue";
import Main from "@/components/atoms/Main.vue";

export default {
  components: {
    Footer,
    Main,
  },
  props: {
    contact: { required: false, type: Boolean, default: false },
    footerClass: { required: false, type: String, default: "" },
  },
};
</script>
