const useGetLink = ({ path }) => {
  let link = "https://psd.interplayers.com.br/amgen/rastrear/app/login";

  if (path === "pulmao") {
    link = "https://rastrearpulmao.com.br/";
  }

  return { link };
};

export default useGetLink;
