<template>
  <nav
    class="m-nav__desktop h-[46px] fixed top-9 z-40 items-center justify-end text-[10px] w-full transition-all delay-150 duration-300 ease-in-out lg:h-[66px] lg:text-xs xl:text-sm"
    :class="
      menuOpen
        ? 'right-0'
        : 'right-[-260px] lg:right-[-320px] xl:right-[-430px]'
    "
  >
    <div class="bg-white rounded-l-full shadow-menu flex items-center">
      <button
        class="ml-[-60px] mr-7 flex items-center before:w-0 before:h-0 before:border-solid before:border-t-[3.5px] before:border-b-[3.5px] before:border-t-[transparent] before:border-b-[transparent] lg:before:border-t-[4.5px] lg:before:border-b-[4.5px] lg:mr-10 lg:ml-[-82px] xl:mr-16"
        :class="
          menuOpen
            ? 'before:border-r-[0] before:border-l-[7px] before:border-r-[transparent] before:border-l-[#fff] lg:before:border-l-[8px]'
            : 'before:border-r-[7px] before:border-l-[0] before:border-r-[#fff] before:border-l-[transparent] before:border-r-[8px]'
        "
        type="button"
        @click="toggleMenu"
      >
        <inline-svg
          :aria-label="!menuOpen ? 'Abrir Menu' : 'Fechar Menu'"
          :src="require('@/assets/novo/dna-menu.svg')"
          class="h-[46px] lg:h-[66px]"
          title="Menu"
        />
        <inline-svg
          aria-label="Logo RAStrear"
          class="w-12 lg:w-[72px]"
          :src="require('@/assets/novo/logo-rastrear-menu.svg')"
          title="Logo RAStrear"
        />
      </button>
      <ul class="flex items-center">
        <li
          v-for="{
            desktoptitle,
            icon,
            link,
            linkClass,
            spanClass,
            title,
          } in menuItems"
          :key="title"
          class="mr-4 xl:mr-8"
        >
          <router-link class="flex items-center" :to="link">
            <span
              class="flex items-center justify-center rounded-full mr-2 shadow-lg w-7 h-7 lg:w-[38px] lg:h-[38px] xl:w-[58px] xl:h-[58px]"
              :class="linkClass"
            >
              <inline-svg
                :aria-label="title"
                class="w-4 lg:w-6 xl:w-8"
                :src="icon"
                :title="title"
              />
            </span>
            <span
              class="uppercase font-bold flex-1"
              :class="spanClass"
              v-html="desktoptitle ?? title"
            />
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { inject } from "vue";

export default {
  name: "DesktopNav",
  setup() {
    const menuItems = inject("menuItems");
    const menuOpen = inject("menuOpen");
    const toggleMenu = inject("toggleMenu");

    return {
      menuItems,
      menuOpen,
      toggleMenu,
    };
  },
};
</script>
