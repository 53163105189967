import Home from "@/components/pages/colorretal/Index.vue";

const colorretalTitle = "Câncer Colorretal Metastástico";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Amgen Biotecnologia",
    },
    children: [
      {
        alias: "/",
        path: "",
        name: "SobreOProgramaColorretal",
        component: () =>
          import(
            /* webpackChunkName: "sobreoprograma" */ "@/components/pages/colorretal/SobreOPrograma.vue"
          ),
        meta: {
          noTop: true,
          title: `${colorretalTitle} - O Programa`,
        },
      },
      {
        path: "orientacoes",
        name: "OrientacoesColorretal",
        component: () =>
          import(
            /* webpackChunkName: "orientacoes" */ "@/components/pages/colorretal/Orientacoes.vue"
          ),
        meta: {
          noTop: true,
          title: `${colorretalTitle} - Orientações`,
        },
      },
      {
        path: "regulamento",
        name: "RegulamentoEDocumentosColorretal",
        component: () =>
          import(
            /* webpackChunkName: "regulamento-e-documentos" */ "@/components/pages/colorretal/RegulamentoEDocumentos.vue"
          ),
        meta: {
          noTop: true,
          title: `${colorretalTitle} - Regulamento`,
        },
      },
      {
        path: "bempertodoseuamanha",
        name: "BemPertoDoSeuAmanha",
        component: () =>
          import(
            /* webpackChunkName: "bempertodoseuamanha" */ "@/components/pages/colorretal/BemPertoDoSeuAmanha.vue"
          ),
        meta: {
          noTop: true,
          title: `${colorretalTitle} - BemPerto® Do Seu Amanhã`,
        },
      },
      {
        path: "politica-de-privacidade",
        name: "PoliticaDePrivacidadeColorretal",
        component: () =>
          import(
            /* webpackChunkName: "politica-de-privacidade" */ "@/components/pages/colorretal/PoliticaDePrivacidade.vue"
          ),
        meta: {
          sub: true,
          title: `${colorretalTitle} - Política De Privacidade`,
        },
      },
    ],
  },
  {
    path: "/o-programa",
    name: "OPrograma",
    component: () =>
      import(
        /* webpackChunkName: "o-programa" */ "@/components/pages/OPrograma.vue"
      ),
    meta: {
      title: "Sobre o Programa",
    },
  },
  {
    path: "/termos-de-uso",
    name: "TermosDeUso",
    component: () =>
      import(
        /* webpackChunkName: "termos-de-uso" */ "@/components/pages/TermosDeUso.vue"
      ),
    meta: {
      title: "Termos De Uso",
    },
  },
];
