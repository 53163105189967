<template>
  <main class="a-main pt-4 pb-8 relative z-10">
    <slot />
  </main>
</template>

<script>
export default {
  name: "Main",
};
</script>
