<template>
  <teleport to="body">
    <div
      :class="{
        'opacity-100 pointer-events-auto': redirect,
        'opacity-0 pointer-events-none': !redirect,
      }"
      class="m-redirect bg-black/80 w-screen h-screen z-50 fixed inset-0 px-4 flex transition-all duration-200 ease-in"
    >
      <div
        class="m-redirect__message bg-white font-bold rounded-full text-center pt-6 pb-20 px-4 m-auto relative flex flex-col-reverse w-[60vw] md:p-12 lg:flex-row-reverse lg:text-left lg:flex-wrap lg:items-center lg:justify-center lg:w-[52vw] lg:max-w-[532px]"
        :class="textColor"
      >
        <div
          class="m-redirect__text text-lg leading-tight md:text-2xl lg:text-xl lg:flex-1"
        >
          Você está sendo redirecionado para o sistema de solicitação de exames.
        </div>
        <div
          class="m-redirect__loader h-24 mb-4 relative before:bg-no-repeat before:bg-center before:absolute before:w-24 before:h-full before:z-20 lg:h-20 lg:mb-0 lg:w-14 lg:mr-8"
          :class="`color-${redirectColor}`"
        >
          <div>
            <inline-svg
              aria-label="check"
              class="w-9 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
              :src="check"
              title="check"
            />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { inject, ref, watch } from "vue";
import useLockScroll from "@/composables/useLockScroll";

export default {
  name: "Redirect",
  setup() {
    const scroll = useLockScroll();
    const redirect = inject("redirect");
    const redirectColor = "green";
    const textColor = ref("text-[#014043]");
    const check = ref("@/assets/novo/check-green.svg");

    watch(redirect, (v) => (v ? scroll.lock() : scroll.unlock()));

    return { check, redirect, redirectColor, textColor };
  },
};
</script>

<style lang="postcss">
.m-redirect {
  &__message {
    max-width: 480px;

    @media screen(md) {
      max-width: 640px;
    }

    @media screen(lg) {
      max-width: 810px;
    }

    @media screen(xl) {
      max-width: 952px;
    }
  }

  &__loader {
    &::before {
      background-image: url("../../assets/novo/loader-novo.svg");
      background-size: 100%;
      left: 50%;
      transform: translateX(-50%);

      @media screen(lg) {
        @apply bg-contain;
      }
    }

    &.color-green {
      &::before {
        background-image: url("../../assets/novo/loader-novo-green.svg");
      }
    }
  }
}
</style>
