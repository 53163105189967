<template>
  <a id="back2Top" @click="() => backToTop()">
    <img src="@/assets/enter.svg" alt="Voltar para o topo da página" />
  </a>
</template>

<script>
import { onMounted, onUnmounted } from "vue";

export default {
  name: "BackToTop",
  setup() {
    function handleScroll() {
      const back2TopButton = document.getElementById("back2Top");

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        return back2TopButton.classList.add("show");
      }

      return back2TopButton.classList.remove("show");
    }

    function backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    onMounted(() => window.addEventListener("scroll", handleScroll));
    onUnmounted(() => window.removeEventListener("scroll", handleScroll));

    return {
      backToTop,
    };
  },
};
</script>

<style lang="postcss">
@media screen(md) {
  #back2Top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 60px;
    height: 60px;
    background: transparent
      radial-gradient(closest-side at 41% -11%, #4baae6 0%, #298ece 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 60px;
    border: none;
    cursor: pointer;
    overflow: hidden;
    opacity: 0;
    transition: all 0.6s ease-in-out;
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      height: 35px;
      transform: rotate(270deg);
    }
  }

  #back2Top.show {
    opacity: 1;
    z-index: 9999;
  }
}
</style>
