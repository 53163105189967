<template>
  <Dialog :onClose="onCloseModal" :open="canShowModal">
    Este site utiliza cookies para oferecer a você a melhor e mais relevante
    experiência. Ao utilizar este site e continuar a navegar, você concorda com
    a nossa política de utilização de cookies. Para obter mais informações sobre
    cookies, visite nossa
    <a
      class="font-semibold underline italic"
      href="https://www.amgen.com.br/privacy-statement/"
      target="_blank"
    >
      Política de Privacidade </a
    >.
  </Dialog>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import { useCookies } from "vue3-cookies";
import Dialog from "./Dialog.vue";

const AMGEN_LGPD_COOKIE = "amgenLGPD";

export default {
  name: "LGPD",
  components: {
    Dialog,
  },
  setup() {
    const isPrerendering = inject("isPrerendering");
    const canShowModal = ref(false);
    const { cookies } = useCookies();

    const onCloseModal = () => {
      canShowModal.value = false;
      cookies.set(AMGEN_LGPD_COOKIE);
    };

    onMounted(() => {
      if (!isPrerendering.value) {
        canShowModal.value = !cookies.isKey(AMGEN_LGPD_COOKIE);
      }
    });

    return { canShowModal, onCloseModal };
  },
};
</script>
