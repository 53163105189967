<template>
  <div id="nav" class="m-nav w-full h-[66px]">
    <MobileNav class="md:hidden" />
    <DesktopNav class="hidden md:flex" />
  </div>
</template>

<script>
import DesktopNav from "@/components/molecules/DesktopNav.vue";
import MobileNav from "@/components/molecules/MobileNav.vue";

export default {
  name: "MNav",
  components: {
    DesktopNav,
    MobileNav,
  },
};
</script>
