<template>
  <div
    class="flex justify-center w-full px-6 md:max-w-[432px] md:mx-auto lg:max-w-[632px]"
  >
    <button
      class="border border-solid rounded-[40px] flex justify-center text-sm w-full py-2 bg-white items-center shadow-button md:py-3 md:text-base lg:py-4 lg:text-lg"
      :class="`${textColor} ${borderColor}`"
      @click.prevent="onClick"
      type="button"
    >
      <inline-svg
        aria-label="Solicitar Exames e Resutados"
        class="mr-2 w-6 md:w-8"
        :src="icon"
        title="DNA"
      />
      <span class="font-bold"> Solicitar Exames e Resultados </span>
    </button>
  </div>
</template>

<script>
import useRedirect from "@/composables/useRedirect";
import { inject } from "vue";
import useGetLink from "@/composables/useGetLink";

export default {
  name: "BigButton",
  props: {
    color: {
      type: String,
      required: false,
      default: "green",
    },
  },
  setup() {
    const borderColor = "border-[#014043]";
    const textColor = "text-[#014043]";
    const icon = require("@/assets/novo/dna-lupa-green.svg");
    const redirect = inject("redirect");
    let path = "colorretal";
    const { link } = useGetLink({ path });

    return {
      borderColor,
      icon,
      onClick: () => useRedirect({ link, redirect }),
      textColor,
    };
  },
};
</script>
