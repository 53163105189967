<template>
  <nav
    class="m-nav__mobile absolute top-0 right-0 flex flex-col items-end z-50 before:bg-white before:absolute before:w-full before:rounded-b-full before:left-0 before:top-0 before:h-full before:shadow-xl after:bg-black/40 after:fixed after:w-screen after:left-0 after:top-0 after:h-screen after:-z-1"
    :class="menuOpen ? 'w-screen min-h-[40vh]' : 'before:hidden after:hidden'"
  >
    <button
      class="menu-action w-6 m-5 z-10 relative"
      type="button"
      @click="toggleMenu"
    >
      <inline-svg
        aria-label="Abrir Menu"
        class="inline-block md:w-full md:max-w-5xl open-icon"
        :class="menuOpen ? 'hidden' : ''"
        :src="require('@/assets/menu.svg')"
        title="Menu"
      />
      <inline-svg
        aria-label="Fechar Menu"
        class="inline-block md:w-full md:max-w-5xl close-icon"
        :class="!menuOpen ? 'hidden' : ''"
        :src="require('@/assets/fechar.svg')"
        title="Fechar"
      />
    </button>
    <ul
      class="relative z-10 flex flex-col justify-center self-center bg-dna bg-no-repeat bg-center bg-contain pt-6 pb-5"
      :class="!menuOpen ? 'hidden' : ''"
    >
      <li
        v-for="{ icon, link, linkClass, title } in menuItems"
        :key="title"
        class="w-[180px] mb-5"
      >
        <router-link
          class="rounded-[30px] uppercase px-5 py-2 h-[64px] flex items-center justify-center text-white relative text-xs shadow-2xl before:bg-menu-blur before:absolute before:top-0 before:left-0 before:w-full before:h-full before:pointer-events-none"
          :class="linkClass"
          :to="link"
        >
          <inline-svg
            :aria-label="title"
            class="inline-block md:w-full md:max-w-5xl mr-3 w-8"
            :src="icon"
            :title="title"
          />
          <span class="w-[88px]">{{ title }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { inject } from "vue";

export default {
  name: "MobileNav",
  setup() {
    const menuItems = inject("menuItems");
    const menuOpen = inject("menuOpen");
    const toggleMenu = inject("toggleMenu");

    return {
      menuItems,
      menuOpen,
      toggleMenu,
    };
  },
};
</script>
