<template>
  <div class="a-logo">
    <inline-svg
      aria-label="Logo Rastrear"
      class="w-full"
      :src="require('@/assets/novo/logo-rastrear-azul.svg')"
      :title="title"
    />
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    title: {
      default: "Rastrear",
      required: false,
      type: String,
    },
  },
};
</script>
